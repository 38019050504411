body {
  font-family: 'Lora';
}

h1,
h2,
h3,
h4,
h5,
.card-body .card-title {
  font-family: 'Lora';
  font-size: 1.3rem;
  font-weight: 500;
  text-align: center;
}

h2 {
  color: #e6a756;
}

.card {
  filter: drop-shadow(1px 1px 2px black);
}
.card-img {
  width: 100%;
  height: 15vw;
  object-fit: cover;
}

.row-content {
  padding: 50px 0;
  border-bottom: 1px ridge;
  min-height: 200px;
}

.jumbotron {
  display: fluid;
  padding: 2rem 1rem;
  background-image: url('../src/app/assets/img/bg.png');
  background-size: cover;
  background-color: rgb(122, 109, 59);
}

.jumbotron img {
  max-width: 60%;
  height: auto;
  float: right;
  filter: drop-shadow(5px 5px 10px black);
}

.page-section {
  margin-top: 5rem;
  margin-bottom: 5rem;
}

.bg-faded {
  background-color: #FFFAE3;
  filter: drop-shadow(1px 1px 2px black);
  margin-bottom: 2rem;
}

.intro {
  position: relative;
}
@media (min-width: 992px) {
  .intro .intro-img {
    width: 75%;
    float: right;
  }
  .intro .intro-text {
    left: 0;
    width: 60%;
    margin-top: 3rem;
    position: absolute;
  }
  .intro .intro-text .intro-button {
    width: 100%;
    left: 0;
    position: absolute;
    bottom: -2rem;
  }
}
@media (min-width: 1200px) {
  .intro .intro-text {
    width: 45%;
  }
}

.img-fluid {
  max-width: 100%;
  height: auto;
  filter: drop-shadow(1px 1px 2px black);
}

.section-heading {
  text-transform: uppercase;
}
.section-heading .section-heading-upper {
  display: block;
  font-size: 1.25rem;
  font-weight: 800;
}
.section-heading .section-heading-lower {
  display: block;
  font-size: 3rem;
  font-weight: 100;
}

.site-footer {
  background-image: linear-gradient(#cec8ff, white);
  padding: 20px 0;
  margin-top: 2rem;
  color: #e6a756;
  font-weight: 500;
}

.site-heading {
  margin-top: 1rem;
  margin-bottom: 1rem;
  text-transform: uppercase;
  line-height: 1;
  font-family: 'Helvetica Neue';
  text-align: left;
  text-shadow: 1px 1px 3px #000000;
}

.site-heading .site-heading-upper {
  display: block;
  font-size: 1.75rem;
  font-weight: 800;
  color: #e6a756;
}

.site-heading .site-heading-lower {
  font-size: 3.75rem;
  font-weight: 100;
  line-height: 4rem;
  color: whitesmoke;
}

.navbar-dark {
  background-color: #901cad;
  background-image: linear-gradient(to right, #020202, #3046c5, #510e5a);
  margin-bottom: 1rem;
  min-height: 3rem;
  filter: drop-shadow(1px 1px 2px black);

}

.navbar-toggler-icon {
  background-image: url('../src/app/assets/img/toggler.svg') !important;
  width: 45px !important;
  height: 45px !important;
}

.badge {
  padding-left: 9px;
  padding-right: 9px;
  -webkit-border-radius: 9px;
  -moz-border-radius: 9px;
  border-radius: 9px;
}

.label-warning[href],
.badge-warning[href] {
  background-color: #c67605;
}
#lblCartCount {
    font-size: 12px;
    background: #ff0000;
    color: #fff;
    padding: 0 5px;
    vertical-align: top;
    margin-left: -10px; 
}

.page-enter {
  opacity: 0;
  transform: translateX(-100%);
}

.page-enter-active {
  opacity: 1;
  transform: translateX(0%);
  transition: 300ms ease-out;
}

.page-exit {
  opacity: 1;
}

.page-exit-active {
  opacity: 0;
  transition: 300ms ease-in;
}

.cartItem{
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  border-bottom: 1px solid gainsboro;
  padding-bottom: 20px;
}

.cartItem:last-child{
  border-bottom: none;
}

.cartItem-info{
  padding-left: 20px;
}

.cartItem-info > button{
  border: unset;
  margin-top: 10px;
  border-radius: 5px;
  background-color: transparent;
}

.cartItem-image{
  object-fit: contain;
  width: 180px;
  height: 180px;
  filter: drop-shadow(1px 1px 2px black);

}

.cartItem-title{
  font-size: 20px;
  font-weight: 450;
}

.cartItem-price{
  font-size: 20px;
  line-height: 35px;
}

.cartItem-incrDec{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 10px 0;
  width: 100px;
}

.cartItem-incrDec button{
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: gainsboro;
  border: none;
  border-radius: 100px;
}

.cartItem-incrDec button:hover{
  box-shadow: 0 2px 2px gray;
}

.cartItem-incrDec button:active{
  box-shadow: unset;
}

.cartItem-removeButton{
  color: rgb(241, 131, 131);
  float: left;
}

.cartItem-removeButton:hover{
  color: rgb(243, 92, 92);
}

.cartItem-removeButton:active{
  color: rgb(241, 131, 131);
}

@media(max-width: 1000px){

  .cartItem-title{
    font-size: 18px;
  }

  .cartItem-price{
    font-size: 18px;
  }
}

@media(max-width:400px){
  .cartItem{
    flex-direction: column;
  }

  .cartItem-title{
    margin-top: 15px;
  }
}

.total{
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 270px;
  height: 110px;
  padding: 20px;
  border-radius: 4px;
  min-width: 350px;
}

.total > h2{
  font-weight: 400;
}

.total-p{
  font-size: 20px;
  font-weight: 450;
  color: rgb(72, 77, 77);
  width: unset;
  text-align: center;
}

.total > button:active{
  background-color: unset;
  border: 1px solid #FFD814;
}

.error{
  position: absolute;
  color: rgb(216, 0, 0);
}